import React from 'react';

export default function LogoFull() {
  return (
    <div className="d-flex align-items-center mb-2 mb-sm-4">
      <div id="crafted-logo-container">
        <img src="/assets/images/crafted_logo.png" alt="Join Crafted Logo" />
      </div>
      <span className="cover-heading mb-0 ml-3">Crafted</span>
    </div>
  );
}

import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import EmailForm from '../components/EmailForm';
import LogoFull from '../components/LogoFull';
import { Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <Header />
    <div>
        <div className="master-container">
          <main className="form-main" role="main">
            <div className="content-left d-flex flex-column justify-content-center align-items-center">
              <div className="content-left-inner d-flex flex-column align-items-center mb-5">
                <LogoFull></LogoFull>
                <h1 className='d-none'>Crafted - All Editions</h1>
                <div id="main-text-container" className="text-center">
                  <ul className="pl-0 my-4 list-unstyled text-left">
                  <li className="text-dark-blue py-3">
                    <Link to={ '/001-duncans-brewing' }>
                      &#35;001 Duncan&apos;s Brewing - Paraparaumu, New Zealand
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/002-incinerati-brewing' }>
                      &#35;002 Mike Sumaya, Incinerati Brewing - California, USA
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/003-eric-portelance-slake-brewing' }>
                      &#35;003 Eric Portelance, Slake Brewing - Ontario, Canada
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/004-jacob-pressey-humboldt-regeneration' } activeStyle={{ color: "rgba(40, 50, 78, 0.5)" }} >
                      &#35;004 Jacob Pressey, Humboldt Regeneration Farm &amp; Brewery - California, USA
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/005-devin-singley-fortnight-brewing' } activeStyle={{ color: "rgba(40, 50, 78, 0.5)" }} >
                      &#35;005 Devin Singley, Fortnight Brewing - North Carolina, USA
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/006-scot-messner-district-1-brewing' } activeStyle={{ color: "rgba(40, 50, 78, 0.5)" }} >
                      &#35;006 Scot Messner, District 1 Brewing - Wisconsin, USA
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/007-nick-babcock-bearded-owl-brewing' } activeStyle={{ color: "rgba(40, 50, 78, 0.5)" }} >
                      &#35;007 Nick Babcock, Bearded Owl Brewing - Illinois, USA
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/008-toast-tiensvold-toasty-bros' } activeStyle={{ color: "rgba(40, 50, 78, 0.5)" }} >
                      &#35;008 Toast Tiensvold, Toasty Bros. Brewing - Texas, USA
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/009-jonathan-elliott-battery-245' } activeStyle={{ color: "rgba(40, 50, 78, 0.5)" }} >
                      &#35;009 Jonathan Elliott, Battery 245 - Oregon, USA
                    </Link>
                  </li>

                  <li className="text-dark-blue py-3">
                    <Link to={ '/010-edgar-huitema-the-beerologist' } activeStyle={{ color: "rgba(40, 50, 78, 0.5)" }} >
                      &#35;010 Edgar Huitema, The Beerologist - Scotland
                    </Link>
                  </li>

                </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div id="overlay">
          <div id="loader-object"></div>
        </div>
        <footer className="mastfoot mt-auto">
          <div className="inner">
          </div>
        </footer>
      </div>
    <Footer />
  </Layout>
);

export default IndexPage;

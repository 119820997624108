import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/main.scss';

class Layout extends Component {
  constructor() {
    super();
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  render() {
    const { children } = this.props;
    const { isPreloaded } = this.state;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet>
              <html lang="en" />
              <meta charSet="utf-8" />
              <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
              {/* Primary Meta Tags */}
              <title>Crafted - Craft Brewers share tips and insights into their work</title>
              <meta name="title" content="Crafted - A newsletter for craft beer lovers" />
              <meta name="description" content="Each week we tell the story of a great beer and the master that made it." />
              {/* Open Graph / Facebook */}
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://joincrafted.com" />
              <meta property="og:title" content="Crafted - A newsletter for craft beer lovers" />
              <meta property="og:description" content="Each week we tell the story of a great beer and the master that made it." />
              <meta property="og:image" content="https://www.joincrafted.com/assets/images/adam-wilson-ANK5zq-g_-g-unsplash_resized.jpg" />
              {/* Twitter */}
              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://joincrafted.com" />
              <meta property="twitter:title" content="Crafted - A newsletter for craft beer lovers" />
              <meta property="twitter:description" content="Each week we tell the story of a great beer and the master that made it." />
              <meta property="twitter:image" content="https://www.joincrafted.com/assets/images/adam-wilson-ANK5zq-g_-g-unsplash_resized.jpg" />
              <link rel="icon" href="favicon.ico" />
              <title>Crafted</title>
            </Helmet>
            <div className={isPreloaded ? 'main-body is-preload' : 'main-body'}>
              {children}
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

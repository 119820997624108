import React, { Component } from 'react';

export class EmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '' };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  componentDidMount() {
    const emailInputs = document.querySelectorAll('main .subscribe-form input.form-control');
    const emailButtons = document.querySelectorAll('.btn-subscribe');

    emailButtons.forEach(function(emailButton) {
      emailButton.addEventListener('mouseenter', e => {
        emailInputs.forEach(function(emailInput) {
          emailInput.classList.add('green');
        });
      });
    });

    emailButtons.forEach(function(emailButton) {
      emailButton.addEventListener('mouseleave', e => {
        emailInputs.forEach(function(emailInput) {
          emailInput.classList.remove('green');
        });
      });
    });

    emailInputs.forEach(function(emailInput) {
      emailInput.addEventListener('focus', e => {
        emailButtons.forEach(function(emailButton) {
          emailButton.classList.add('green');
        });
      });
    });

    emailInputs.forEach(function(emailInput) {
      emailInput.addEventListener('blur', e => {
        emailButtons.forEach(function(emailButton) {
          emailButton.classList.remove('green');
        });
      });
    });

    const autofocus = this.props.autofocus

    if (autofocus) {
      document.querySelector(".sign-up-email-input").focus();
    }
  }

  handleEmailChange(e) {
     this.setState({email: e.target.value});
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const overlayObject = document.querySelector('#overlay');
    const loaderObject = document.querySelector('#loader-object');
    const masterContainer = document.querySelector('.master-container');

    if (overlayObject) {
      overlayObject.style.display = 'flex';
    }
    if (loaderObject) {
      loaderObject.classList.add('loader');
    }
    if (masterContainer) {
      masterContainer.classList.add('blurred');
    }

    this.setState({email: e.target.value});

    const emailAddress = this.state.email

    if (document.querySelector("[name='humans-only']").value.length > 0) {
      fetch('/.netlify/functions/email-signup', {
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email_address: emailAddress
        }),
        method: 'POST'
      }).then(response => {
        if (response.status === 200) {
          document.location = "/sign-up-success";
        } else {
          window.alert('Oops! Something is not working. Please email us directly at hello@joincrafted.com to sign up.');
        }
      })
    }
  }

  render() {
    return (
      <form id="sign-up" name="sign-up" onSubmit={this.onSubmit} method="POST" action="#" netlify-honeypot="humans-only" className="subscribe-form mt-4 px-2">
        <p className="d-none">
          <label>Bot trap, not for humans: <input name="humans-only" /></label>
        </p>
        <div className="input-group">
          <input type="email" value={this.state.email || ''} onChange={this.handleEmailChange} className="form-control sign-up-email-input" placeholder="Your email" aria-label="Your email address" aria-describedby="email" required />
          <div className="input-group-append">
            <button id="btn-subscribe" className="btn btn-subscribe" type="submit">Subscribe</button>
          </div>
        </div>
      </form>
    );
  }
}

export default EmailForm;
